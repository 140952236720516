import React from 'react';
import PropTypes from 'prop-types';

import './view.scss';

export const Accordion = ({ icon, title, children }) => (
    <div className="accordion__wrapper">
        <details className="accordion">
            <summary className="accordion__summary">
                {/* inner div is fix for Safari where summary tag not support display property */}
                <div className="accordion__summary-content">
                    {
                        icon
                        && <div className="accordion__summary-icon" style={{ backgroundImage: `url(${icon})` }} />
                    }
                    <span>{ title }</span>
                </div>
            </summary>
            <div className="accordion__content">
                { children }
            </div>
        </details>
    </div>
);

Accordion.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
};

Accordion.defaultProps = {
    icon: undefined
};