import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { FilesView, fileType } from 'features/downloads/files/component/view';

export const Guarantees = () => {
    const data = useStaticQuery(graphql`
        query GetGuarantees {
            allDownloadfiles(filter: {id: {ne: "dummy"}}) {
                nodes {
                data {
                    alternative_id
                    alt_file {
                        pl
                    }
                    file {
                        pl
                    }
                    title {
                        pl
                    }
                    type
                    }
                }
            }
        }
    `);

    return (<FilesView data={ data?.allDownloadfiles?.nodes[0]?.data.filter((item) => item.type === fileType.signBook) } />);
};