import React from 'react';
import PropTypes from 'prop-types';

import { Img } from 'utilities/components/img/component';

import iconDocument from 'assets/images/downloads/icon--document.png';

import './view.scss';

export const File = ({ link, name, alt }) => (
    <a href={ link } download className="file">
        <Img src={ iconDocument } alt={ alt } className="file__icon" />
        <div className="file__name">{ name }</div>
        {/* TODO - wait for it */}
        {/* <div className="file__size">177 kB</div> */}
    </a>
);

File.propTypes = {
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};