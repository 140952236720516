import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';

import { Navigation } from 'utilities/components/navigation/component';
import { Footer } from 'utilities/components/footer/component';
import { BannerView } from 'utilities/components/banner/component/view';
import LogRocket from 'logrocket';
import { Categories } from 'features/downloads/categories/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

LogRocket.init('qijaj0/baranski');

const Downloads = () => {
    const breadcrumbs = [routes.home, routes.doPobrania];
    const data = useStaticQuery(graphql`
        query GetBannerDoPobrania {
            banners(site_type: { eq: "Do pobrania" }) {
                image
                alt_image
                background_position_x
            }
        }
    `);
    const { metaTitle, metaDescription, description } = useSeoTexts('Do Pobrania');

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };

    return (
        <main className="downloads">
            <SEO
                noindex
                title={ metaTitle || 'Barański Drzwi - Materiały do pobrania - instrukcje, druki' }
                description={
                    metaDescription
                    || 'Pobierz nasze katalogi, druki zamówień, karty gwarancyjne, instrukcje, logo, formularze reklamacyjne.'
                }
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                alt={ data?.banners?.alt_image }
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <BannerView
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                headline="Do pobrania"
                x={ data?.banners?.background_position_x }
            />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <Categories />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Downloads;
