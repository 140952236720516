import React from 'react';

import { Accordion } from 'utilities/components/accordion/component/view';
import { List } from 'features/downloads/catalogues/component';
import { Prints } from 'features/downloads/prints/component';
import { Guarantees } from 'features/downloads/guarantees/component';
import { Complaints } from 'features/downloads/complaints/component';
import { Logos } from 'features/downloads/logos/component';

import iconBook from 'assets/images/downloads/icon--book.svg';
import iconPrints from 'assets/images/downloads/icon--prints.svg';
import iconCards from 'assets/images/downloads/icon--cards.svg';
import iconForm from 'assets/images/downloads/icon--form.svg';
import iconLogo from 'assets/images/downloads/icon--logo.svg';

import './view.scss';

// TODO - if List, Prints etc. have the same type of data - create universal component
export const CategoriesView = () => (
    <section className="categories">
        <Accordion icon={ iconBook } title="Katalogi">
            <List />
        </Accordion>
        <Accordion icon={ iconPrints } title="Druki zamówień">
            <Prints />
        </Accordion>
        <Accordion icon={ iconCards } title="Karty gwarancyjne & instrukcje montażu">
            <Guarantees />
        </Accordion>
        <Accordion icon={ iconForm } title="Formularze reklamacyjne">
            <Complaints />
        </Accordion>
        <Accordion icon={ iconLogo } title="Logo">
            <Logos />
        </Accordion>
    </section>
);