import React from 'react';
import PropTypes from 'prop-types';

import { File } from 'utilities/components/file/component/view';

export const fileType = {
    signBook: 'Karty gwarancyjne & instrukcje montażu',
    logo: 'Logo',
    prints: 'Druki zamówień',
    complaints: 'Formularz reklamacyjny'
};

export const FilesView = ({ data }) => (
    <section className="file-list">
        {
            data && data.map((currentValue, index) => (
                <File key={ index } link={ `${process.env.GATSBY_URL}/${currentValue.file.pl}` } name={ currentValue.title.pl } alt={ currentValue.alt_file.pl } />
            ))
        }
    </section>
);

FilesView.propTypes = {
    data: PropTypes.instanceOf(Array).isRequired
};