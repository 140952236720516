import React from 'react';
import PropTypes from 'prop-types';

import { ButtonView } from 'utilities/components/button/component/view';
import { Img } from 'utilities/components/img/component';

import './view.scss';

export const ListView = ({ catalogs }) => (
    <section className="list">
        {
            catalogs.map((currentValue, index) => (
                <div className="list__catalog" key={ index }>
                    <div className="catalog__image-wrapper">
                        <Img className="catalog__image" src={ `${process.env.GATSBY_URL}/${currentValue.thumbnail_img}` } alt={ currentValue.alt_thumbnail_img } />
                    </div>
                    <div className="catalog__content">
                        <h4 className="catalog__headline" style={{ color: currentValue.brand_color }}>{ currentValue.name }</h4>
                        <ButtonView text="Pobierz katalog" link={ `${process.env.GATSBY_URL}/${currentValue.folder_file}` } download target="_blank" />
                    </div>
                </div>
            ))
        }
    </section>
);

ListView.propTypes = {
    catalogs: PropTypes.instanceOf(Array).isRequired
};